import React from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
    width: 100%;
    background: white;
`;

const FooterSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 55px 0;

    @media (max-width: 1300px) {
        padding: 55px 20px;
    }

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

const Divider = styled.div`
    background-color: #E3E3E3;
    width: 100vw;
    height: 1px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;

    @media (max-width: 500px) {
        text-align: center;
    }
`;

const CopyRight = styled.p`
    font-weight: 300;
    color: #5892A6;
    font-size: 14px;
`;

const TelNo = styled.a`
    color: #5892A6;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
`;

const Email = styled.a`
    color: #5892A6;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    margin: 7px 0;
`;

const LogoWrapper = styled.a`
    cursor: pointer;
    width: 234px;

    @media (max-width: 769px) {
        width: 180px;
    }
    @media (max-width: 500px) {
        margin-bottom: 30px;
    }
`;

const MinimalFooter = () => {
    return (
        <Wrapper>
            <Divider />

            <FooterSection>
                <LogoWrapper href="/">
                    <StaticImage src="../../../resources/images/logos/Collexin-tag.png" alt="Collexin Logo"/>
                </LogoWrapper>

                <Container>
                    <TelNo href="tel:8883265539">(888) 3-COLLEX (326-5539)</TelNo>
                    <Email href="mailto:cs1@collexin.com">cs1@collexin.com</Email>
                    <CopyRight>Copyright © Collexin Inc.</CopyRight>
                    {/* <CopyRight>Copyright © Collexin Inc.</CopyRight> */}
                </Container>
            </FooterSection>
        </Wrapper>
    );
};


export default MinimalFooter;
